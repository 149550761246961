@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;700;800&display=swap');


body{
  margin: 0;
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 0px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bigHeader{
  font-family: 'Work Sans', sans-serif;
  font-weight: 900;
  font-size: 58px;
  color: white;
}

.bodytext{
  font-family: 'Work Sans', sans-serif;
  font-weight: 300;
  font-size: 19px;
  color: white;
}

.bodytextBullets{
  font-family: 'Work Sans', sans-serif;
  font-weight: 300;
  font-size: 20px;
  margin: 0px;
  padding-left: 20px;
  color: white;
}

.HeaderDesk{
  font-family: 'Work Sans', sans-serif;
  font-weight: 900;
  font-size: 40px;
  color: white;
}

.smallHeaderBereiche{
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: white;
}

.smallHeader{
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-size: 28px;
  color: white;
}

.bulletIcons{
  width: 25px;
  height: 25px;
  color: white;
}

.bodyContainer{
  background-color: #121212;
}

.marginSection{
 margin-top: 180px;
}


/* Mobile Styles*/

.bodytextMob{
  font-family: 'Work Sans', sans-serif;
  font-weight: 300;
  font-size: 17px;
  margin-left: 0px !important;
  color: white;
}

.bigHeaderMob{
  font-family: 'Work Sans', sans-serif;
  font-weight: 900;
  font-size: 35px;
  color: white;
  margin-bottom: 20px;
}

.marBottom35{
  margin-bottom:35px;
}

.marginTB30{
  margin-top: 30px;
  margin-bottom: 30px;
}

.marginLeft10{
  margin-left: 10px;
}

.marginRight20{
  margin-right: 20px;
}

.marginSectionMob{
  margin-top: 80px;
}

.m10{
  margin-top: 10px;
  margin-bottom: 10px;
}

.m20{
  margin-top: 20px;
  margin-bottom: 20px;
}

.m30{
  margin-top: 30px;
  margin-bottom: 30px;
}

.m40{
  margin-top: 40px;
  margin-bottom: 40px;
}

.p30{
  padding-top: 30px;
  padding-bottom: 30px;
}

.bodytextBulletsMob{
  font-family: 'Work Sans', sans-serif;
  font-weight: 300;
  font-size: 18px;
  margin: 0px;
  padding-left: 20px;
  color: white;
}

.handy04{
  transition: all 0.2s;
  width: 380px;
  height: auto;
}

.handy04:hover{
  scale: 1.05;
} 

.p120{
  padding-top: 180px;
}

.smallHeaderBereicheMob{
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-size: 22px;
  color: white;
}

.pt80{
  padding-top: 80px;
}

.marginSectionBot{
  margin-bottom: 150px;
}

h1,h2,h3,h4,h5,h6{
  margin-bottom: 0;
}

.col p{
  margin-left: 0px !important;
}


