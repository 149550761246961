@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;500;700;800&display=swap');

.oneLinerFormFieldDesk{
    height: 54px;
    width: 500px;
    border-radius: 8px;
    background-color: transparent;
    border: solid 1px #B8B8B8;
    margin-bottom: 20px;
}

.mr10{
    margin-right: 10px;
}

.mr20{
    margin-right: 10px;
}

.formText{
    font-family: 'Work Sans', sans-serif;
    font-weight: 300;
    font-size: 17px;
    color: white;
}

.buttonForm{
    transition: all 0.3s;
    width: 300px;
    height: 60px;
    border-radius: 60px;
    background-color: rgba(0, 123, 255, 0.4);
    color: white;
    border: none;
    font-family: "Work Sans";
    font-weight: 700;
    font-size: 19px;
}

.buttonForm:hover{
    transform: translateY(-3px);
    background-color: rgba(0, 123, 255, 0.6);
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.6),0 0 20px rgba(0, 123, 255, 0.6);
}

input{
    padding-left: 15px;
    padding-right: 15px;
}

textarea{
    padding-left: 15px;
    padding-top: 5px;
    padding-right: 15px;
}

.ml10{
    margin-left: 10px;
}

.textAreaFormFieldDesk{
    height: 162px;
    width: 500px;
    border-radius: 8px;
    background-color: transparent;
    border: solid 1px #B8B8B8;
    margin-bottom: 20px;
}

input:focus{
    background-color: rgba(0, 123, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 1);
    outline: none;
}

textarea:focus{
    background-color: rgba(0, 123, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 1);
    outline: none;
}

.envelopeIcon{
    height: auto;
    width: 40px;
    color: white;
}