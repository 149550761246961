@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;700;800&display=swap');

.navbarLinkText{
    font-family: 'Work Sans', sans-serif;
    font-weight: 300;
    font-size: 15px;
    color:white;
  }
  
  .navbarBrandText{
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    font-size: 22px;
    color:white;
  }
  
  .navBarMargin{
    margin-left: 22px;
    margin-right: 22px;
  }
  
  .navBarMarginLeft{
    margin-right: 20px;
  }
  
  .navIconMargin{
    margin-left: 12px;
    margin-right: 12px;
  }

  .socialMediaContainer:hover{
    box-shadow: 0 0 10px rgb(149, 137, 222,1), 0 0 20px rgb(149, 137, 222,1);
    scale: 1.1;
  }
  
  .socialIcon{
    height: 20px;
    width: 20px;
  }

  .appIcon{
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }

  .socialMediaContainer{
    transition: all 0.5s;
    height: 38px;
    width: 38px;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgb(149, 137, 222,1);
    border-radius: 22.5px
  }

  .iconEnvelope{
    height: 18px;
    width: 25px;
  }

  .container-fluid{
    padding: 0;
  }
