@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;300;500;700;800&display=swap');

body{
    background-color: #121212;
}

.buttonLoad{
    transition: all 0.3s;
    width: 250px;
    height: 60px;
    border-radius: 60px;
    background-color: rgb(201, 39, 30,0.4);
    color: white;
    border: none;
    font-family: "Work Sans";
    font-weight: 700;
    font-size: 19px;
}

.HeaderDeskND{
    font-family: 'Work Sans', sans-serif;
    font-weight: 900;
    font-size: 50px;
    color: white;
  }

.buttonLoadPurchase{
    transition: all 0.3s;
    width: 250px;
    height: 60px;
    border-radius: 60px;
    background-color: rgb(149, 137, 222,0.5);
    color: white;
    border: none;
    font-family: "Work Sans";
    font-weight: 700;
    font-size: 19px;
}

.buttonLoadPurchase:hover{
    transform: translateY(-3px);
    background-color: rgb(149, 137, 222,0.7);
    box-shadow: 0 0 10px rgb(149, 137, 222,0.7), 0 0 20px rgb(149, 137, 222,0.7);
}

.buttonLoad:hover{
    transform: translateY(-3px);
    background-color: rgb(201, 39, 30,0.6);
    box-shadow: 0 0 10px rgb(201, 39, 30, 0.6),0 0 20px rgb(201, 39, 30, 0.6);
}

.buttonLoadAndroid{
    transition: all 0.2s;
    width: 280px;
    height: 60px;
    border-radius: 60px;
    background-color: transparent;
    color: white;
    border: solid rgb(201, 39, 30,0.6);
    border-width: 4px;
    font-family: "Work Sans";
    font-weight: 700;
    font-size: 19px;
    margin-left: 20px;
}

.buttonLoadAndroid:hover{
    transform: translateY(-3px);
    background-color: rgb(201, 39, 30,0.6);
    border: 4px solid transparent;
    box-shadow: 0 0 10px rgb(201, 39, 30, 0.6),0 0 20px rgb(201, 39, 30, 0.6);
}


.handy01{
    transition: all 0.2s;
    width: 600px;
    height:auto;
}

.handy02{
    transition: all 0.2s;
    width: 450px;
    height:auto;
}

.handy03{
    transition: all 0.2s;
    width: 500px;
    height:auto;
}

.boxOverview{
    width: 300px;
    height: 200px;
    border-radius: 45px; 
    background:linear-gradient(to top,#121212,rgba(58, 58, 60, 1));
}

.boxOverview2{
    height: 200px;
    border-radius: 45px; 
    background:linear-gradient(to top,#121212,rgba(58, 58, 60, 1));
}

.overViewBig{
    background:linear-gradient(to top,#121212,rgba(58, 58, 60, 1));
    height: 250px;
    border-radius: 40px;
    margin-bottom: 60px; 
}

.fontBoxOverviewHeader{
    font-family: 'Work Sans', sans-serif;
    font-weight: 900;
    font-size: 60px;
    color:white;
    margin:0;
    margin-bottom:0;
  }
  
  .fontBoxOverviewUntertitel{
    font-family: 'Work Sans', sans-serif;
    font-weight: 300;
    font-size: 22px;
    color:white;
    opacity: 0.7;
    margin-top:0;
  }

.sectionOneContainer{
    margin-top: 60px;
    padding-bottom: 50px;
}

.bulletPointVis{
    height: 40px;
    margin-top: 20px;
  }

.overViewBigColumns{
    width: 320px;
    height: 200px;
}

.iconsOverViewBig2{
    height: 60px;
    width: auto;
    color: white;
}

.breiteStars{
    width: 300px;
    margin-bottom: 40px;
}

.starIcon{
    height: 35px;
    width: 35px;
    color: white;
}

/* Mobile Version*/

.buttonLoadMob{
    transition: all 0.3s;
    width: 250px;
    height: 60px;
    border-radius: 60px;
    background-color: rgb(201, 39, 30,0.4);
    color: white;
    border: none;
    font-family: "Work Sans";
    font-weight: 700;
    font-size: 17px;
}

.buttonLoadMob:hover{
    transform: translateY(-3px);
    background-color: rgb(201, 39, 30,0.6);
    box-shadow: 0 0 10px rgb(201, 39, 30, 0.6),0 0 20px rgb(201, 39, 30, 0.6);
}

.buttonLoadAndroidMob{
    transition: all 0.2s;
    width: 250px;
    height: 60px;
    border-radius: 60px;
    background-color: transparent;
    color: white;
    border: solid rgb(201, 39, 30,0.6);
    border-width: 4px;
    font-family: "Work Sans";
    font-weight: 700;
    font-size: 17px;
}

.buttonLoadAndroidMob:hover{
    transform: translateY(-3px);
    background-color: rgb(201, 39, 30,0.6);
    border: 4px solid transparent;
    box-shadow: 0 0 10px rgb(201, 39, 30, 0.6),0 0 20px rgb(201, 39, 30, 0.6);
}

.handy01Mob{
    width: 390px;
    height:auto;
    margin-top: 20px;
}

.boxOverviewMob{
    height: 170px;
    border-radius: 25px; 
    background:linear-gradient(to top,#121212,rgba(58, 58, 60, 1));
    transition: all 0.3s;
}

.boxOverviewMob:hover{
    box-shadow: inset 0px 0px 20px 2px rgba(255, 255, 255, 1);
}

.fontBoxOverviewHeaderMob{
    font-family: 'Work Sans', sans-serif;
    font-weight: 900;
    font-size: 45px;
    color:white;
    margin:0;
    margin-bottom:0;
  }
  
  .fontBoxOverviewUntertitelMob{
    font-family: 'Work Sans', sans-serif;
    font-weight: 300;
    font-size: 18px;
    color:white;
    opacity: 0.7;
    margin-top:0;
  }

  .buttonLoadPurchase{
    transition: all 0.3s;
    width: 250px;
    height: 60px;
    border-radius: 60px;
    background-color: rgb(149, 137, 222,0.5);
    color: white;
    border: none;
    font-family: "Work Sans";
    font-weight: 700;
    font-size: 17px;
}

.buttonLoadPurchase:hover{
    transform: translateY(-3px);
    background-color: rgb(149, 137, 222,0.7);
    box-shadow: 0 0 10px rgb(149, 137, 222,0.7), 0 0 20px rgb(149, 137, 222,0.7);
}

.smallHeaderBereicheMob{
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    font-size: 21px;
    color: white;
    margin-bottom: 10px;
  }

  .overViewBigMob{
    height: 550px;
}

.iconsOverViewBigMob{
    height: 60px;
    width: auto;
    margin-bottom: 15px;
}

.handy03Mob{
    width: 300px;
    height:auto;
}

.handy02Mob{
    width: 350px;
    height:auto;
}

.handy02Tab{
    width: 450px;
    height:auto;
}

.mobContainerPadding{
    padding-bottom: 50px;
}

.arrowHolder{
    background-color: #007bff;
    border-radius: 50%;
    height: 40px;
    width: 40px;
}
