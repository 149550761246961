
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;500;700;800&display=swap');

body{
  padding-bottom: 100px;
}

p{
  margin: 0;
}

a{
  text-decoration: none;
}

.gradHeaderDesk{
  font-family: 'Work Sans', sans-serif;
  font-weight: 900;
  font-size: 50px;
  background: linear-gradient(to bottom right, #9589DE, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

  .ellipseLilaDesk{
    position: absolute;
    width: 170px;
    height: 170px;
    left: 1100px;
    top: 250px;
    background: #9589DE;
    filter: blur(127px);
    z-index:-1;
  }

  .ellipseLilaDesk2{
    position: absolute;
    width: 140px;
    height: 140px;
    left: 1150px;
    top: 2850px;
    background: #9589DE;
    filter: blur(127px);
    z-index:-1;
  }

  .ellipseLilaBlue{
    position: absolute;
    width: 170px;
    height: 170px;
    left: 150px;
    top: 1380px;
    background: rgba(0, 123, 255, 1);
    filter: blur(127px);
    z-index:-1;
  }

  .angebotBoxDesk{
    border-radius: 25px;
    background:linear-gradient(to top,#121212,rgba(0, 123, 255, 0.2));
    width: 300px;
    height: 300px;
  }

  .angebotBoxMob{
    border-radius: 25px;
    background:linear-gradient(to top,#121212,rgba(0, 123, 255, 0.2));
    height: 300px;
  }

  .buttonCTA{
    transition: all 0.3s;
    width: 250px;
    height: 60px;
    border-radius: 60px;
    background-color: rgba(0, 123, 255, 0.4);
    color: white;
    border: none;
    font-family: "Work Sans";
    font-weight: 700;
    font-size: 19px;
}

.buttonCTA:hover{
    transform: translateY(-3px);
    background-color: rgba(0, 123, 255, 0.6);
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.6),0 0 20px rgba(0, 123, 255, 0.6);
}

.iconContainerDesk{
  width: 70px;
  height: 70px;
  border-radius: 35px;
  background-color: rgba(0, 123, 255, 0.7);
}

p{
  padding: 0px;
  margin: 0px,
}

.bullsEye{
  height: auto;
  width: 550px;
}

hr {
  visibility: hidden;
  margin: 0 auto;
  margin-top: 40px;
  width: 20%;
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0));
}

.projectContainer{
  padding-top: 50px;
  padding-bottom: 50px;
}

.btnProjects{
  border-radius: 20px;
  /*background: #0F2236;*/
  height: 400px;
  background: linear-gradient(180deg, rgb(201, 39, 30,0.3) 0%, rgb(201, 39, 30,0.18) 100%);
  /*box-shadow: inset 0px 0px 15px rgba(255, 255, 255, 0.45);*/
  color: white;
}

.buttonProjects{
  transition: all 0.3s;
    width: 200px;
    height: 45px;
    border-radius: 40px;
    background-color: rgba(201, 39, 30,0.85);
    color: white;
    border: none;
    font-family: "Work Sans";
    font-weight: 700;
    font-size: 15px;  
}

.buttonProjects:hover{
  background-color: rgba(201, 39, 30,1);
}

.iconProjectBtn{
  color: white;
}

.ellipsePerson{
  width: 250px;
  height: 250px;
  border-radius: 125px;
  background-color: white;
}

.imgPersonContainer{
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: none;
  box-shadow: 0 0 10px rgba(201, 39, 30, 0.6),0 0 20px rgba(201, 39, 30, 0.6);
  overflow: hidden;
}

.imgPerson{
  width: 100px;
  height: 100px;
}
 .mr30{
  margin-right: 30px;
 }
 
 .marginUnderSection{
   margin-top: 60px;
 }

 .col p{
   margin: 5px;
 }

 .mbot50{
  margin-bottom: 50px;
 }

 /*Mobile Version*/

 .HeaderDeskMob{
  font-family: 'Work Sans', sans-serif;
  font-weight: 900;
  font-size: 32px;
  color: white;
}

.gradHeaderDeskMob{
  font-family: 'Work Sans', sans-serif;
  font-weight: 900;
  font-size: 32px;
  background: linear-gradient(to bottom right, #9589DE, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ellipseLilaMob{
  position: absolute;
    width: 120px;
    height: 120px;
    left: 70%;
    top: 0px;
    background: #9589DE;
    filter: blur(127px);
    z-index:-1;
}

.ellipseBlueMob{
  position: absolute;
    width: 160px;
    height: 160px;
    left: 50%;
    right: 50%;
    top: 3120px;
    transform: translate(-50%, -50%);
    background: rgba(0, 123, 255, 1);
    filter: blur(100px);
    z-index:-1;
}

.projectcontainerMob{
  width: 100%;
  height: 600px;
  border-radius: 45px;
  background: linear-gradient(180deg, rgba(0, 123, 255, 0.2) 0%, rgba(0, 123, 255, 0.12) 100%);
  padding: 15px;
}

.btnProjectsMob{
  transition: all 0.3s;
  width: 250px;
  height: 60px;
  border-radius: 60px;
  background: transparent;
  box-shadow: inset 0px 0px 15px rgba(0, 123, 255, 0.6);
  color: white;
  padding-left: 55px;
  padding-right: 55px;
  border: none;
  font-family: "Work Sans";
  font-weight: 200;
  font-size: 19px;
}

/*Tablet Version*/
.angebotBoxTab{
  border-radius: 25px;
  background:linear-gradient(to top,#121212,rgba(0, 123, 255, 0.2));
  width: 500px;
  height: 300px;
}

.bullsEyeMob{
  width: 300px;
  height: auto;
}

.animationTab{
  width: 400px;
  height:auto;
}

.iconsOverViewBig{
  height: 35px;
  width: auto;
  color: white;
}
