.footerText{
    font-family: 'Work Sans', sans-serif;
    font-weight: 200;
    font-size: 22px;
    color: white;
    opacity: 0.7;
}

.footerText:hover{
    opacity: 1;
    color: rgb(149, 137, 222,1);
}

.bgMobile{
    background-color: rgba(58, 58, 60, 1);
}

.socialMediaContainerFooter{
    transition: all 0.5s;
    height: 45px;
    width: 45px;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgb(149, 137, 222,1);
    border-radius: 50%;
  }

  .socialMediaContainerFooter:hover{
    box-shadow: 0 0 10px rgb(149, 137, 222,1), 0 0 20px rgb(149, 137, 222,1);
    scale: 1.1;
  }

  .iconEnvelopeFooter{
    height: 25px;
    width: auto;
  }


  .socialIconFooter{
    height: 25px;
    width: 25px;
  }

